import xhr from "xhr";

export default {
  name: "api",

  getReducer: () => {
    const root =
      import.meta.env.VITE_DEPLOY_ENV === "development"
        ? import.meta.env.VITE_API_ROOT_DEV
        : import.meta.env.VITE_API_ROOT_PROD;
    const initialState = {
      root: root,
    };

    return (state = initialState, { type, payload }) => {
      return state;
    };
  },

  getExtraArgs: (store) => {
    return {
      apiGet: (path, callback, moreOptions) => {
        const token = store.selectTokenRaw();
        if (!token) return null;
        const root = store.selectApiRoot();
        const options = {
          url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
          withCredentials: path.indexOf("goauth") !== -1,
          ...moreOptions,
        };
        if (token) {
          options.headers = {
            Authorization: "Bearer " + token,
          };
        }
        return xhr.get(options, callback);
      },
      apiPut: (path, payload, callback) => {
        const token = store.selectTokenRaw();
        if (!token) return null;
        const root = store.selectApiRoot();
        const options = {
          url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
          json: payload,
          withCredentials: path.indexOf("goauth") !== -1,
        };
        if (token) {
          options.headers = {
            Authorization: "Bearer " + token,
          };
        }
        return xhr.put(options, callback);
      },
      apiPatch: (path, payload, callback) => {
        const token = store.selectTokenRaw();
        if (!token) return null;
        const root = store.selectApiRoot();
        const options = {
          url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
          json: payload,
          withCredentials: path.indexOf("goauth") !== -1,
        };
        if (token) {
          options.headers = {
            Authorization: "Bearer " + token,
          };
        }
        return xhr.patch(options, callback);
      },
      apiUpload: (path, payload, callback) => {
        const token = store.selectTokenRaw();
        if (!token) return null;
        const root = store.selectApiRoot();

        // Create a FormData object
        let formData = new FormData();
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key]);
        });

        const options = {
          url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
          body: formData,
          withCredentials: path.indexOf("goauth") !== -1,
          method: "POST",
        };

        if (token) {
          options.headers = {
            Authorization: "Bearer " + token,
            // 'Content-Type': "multipart/form-data" // This line is not necessary
          };
        }

        return fetch(options.url, options)
          .then((response) => response.json())
          .then((data) => callback(null, { statusCode: 200 }, data))
          .catch((error) => callback(error));
      },

      apiPost: (path, payload, callback) => {
        const token = store.selectTokenRaw();
        if (!token) return null;
        const root = store.selectApiRoot();
        const options = {
          url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
          json: payload,
          withCredentials: path.indexOf("goauth") !== -1,
        };
        if (token) {
          options.headers = {
            Authorization: "Bearer " + token,
          };
        }
        return xhr.post(options, callback);
      },
      apiDelete: (path, payload, callback) => {
        const token = store.selectTokenRaw();
        if (!token) return null;
        const root = store.selectApiRoot();
        const options = {
          url: path.indexOf("http") !== -1 ? `${path}` : `${root}${path}`,
          withCredentials: path.indexOf("goauth") !== -1,
        };
        if (payload) options.json = payload;
        if (token) {
          options.headers = {
            Authorization: "Bearer " + token,
          };
        }
        return xhr.del(options, callback);
      },
    };
  },

  selectApiRoot: (state) => {
    return state.api.root;
  },
};
