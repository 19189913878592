import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import PrintButton from "../../../../../app-components/print-button/print-button";
import Loader from "../../../../../app-components/loader";
import { keyBy, find } from "lodash";
import GradeSlipTabs from "./grade-slip-tabs";
import GradeSlipForm from "./grade-slip-form";

export const defaultEvalTypes = {
  annual: { property: "event", value: "annual", label: "Annual Comprehensive" },
  post_mishap_flight_eval: {
    property: "event",
    value: "post_mishap_flight_eval",
    label: "Post Flight Mishap",
  },
  proficiency_flight_eval: {
    property: "event",
    value: "proficiency_flight_eval",
    label: "Flight Proficiency",
  },
  no_notice_eval: {
    property: "event",
    value: "no_notice_eval",
    label: "No-Notice",
  },
};

export const defaultGradeSlip = {
  id: null,
  org_id: null,
  summary: null,
  foa: "",
  atpm_sig: "",
  atpm_sig_parsed: {},
  event: "",
  overall_grade: "",
  suac_debrief_complete: "",
  additional_training: "",
  viewed: false,
  mission_id: null,
  mission_name: "",
  name: "",
};

const GradeSlip = ({
  routeInfo,
  orgsItemsAsOptionsWithId,
  doGradeSlipRemoveForm,
  gradeSlipIsSigning: _isSigning,
  gradeSlipIsLoading: _isLoading,
  gradeSlipIsSaving: _isSaving,
  gradeSlipItemsByEvalType,
  doGradeSlipSave,
  profileActiveData,
  isProfileActiveAtpm,
  doGradeSlipUpdateSelectedForm,
  doNestedDialogClose,
  doDialogClose,
  doGradeSlipFetchCategoriesByOrg,
  orgsByRoute,
  gradeSlipAllTypes,
  gradeSlipEvalType,
  gradeSlipSelectedForm,
  doUpdateUrl,
  doGradeSlipUpdateState,
  gradeSlipEditedEntries,
  gradeSlipSelectedFormLastSave,
  gradeSlipEntryItemsObjectByForm,
  gradeSlipEntryItemsPrintableAll,
  gradeSlipSelectableMissions,
}) => {
  const viewedFromProfilePage =
    routeInfo.url && routeInfo.url.includes("/profile");
  const orgsItemsObject = keyBy(orgsItemsAsOptionsWithId, "value");

  const processObjIntoFormValues = (updateObj) => {
    if (!updateObj) return {};
    let {
      atpm_sig,
      suac_debrief_complete,
      additional_training,
      overall_grade,
      mission_id,
      org_id,
    } = updateObj;
    let linkedMission = find(gradeSlipSelectableMissions, [
      "value",
      mission_id,
    ]);
    let atpm_sig_parsed = atpm_sig
      ? JSON.parse(atob(atpm_sig.split(".")[0]))
      : "";
    return {
      ...updateObj,
      suac_debrief_complete_yes: suac_debrief_complete === "yes",
      suac_debrief_complete_no: suac_debrief_complete === "no",
      additional_training_yes: additional_training === "yes",
      additional_training_no: additional_training === "no",
      overall_grade_sat: overall_grade === "sat",
      overall_grade_unsat: overall_grade === "unsat",
      overall_grade_na: overall_grade === "na",
      org_id: orgsByRoute ? orgsByRoute.id : org_id,
      mission_name: linkedMission ? linkedMission.label : "",
      atpm_sig: atpm_sig,
      atpm_sig_parsed: atpm_sig_parsed,
      event_str: gradeSlipAllTypes[gradeSlipEvalType]
        ? gradeSlipAllTypes[gradeSlipEvalType].label
        : "",
    };
  };

  useEffect(() => {
    if (gradeSlipSelectedFormLastSave) {
      doGradeSlipUpdateState({
        _entriesLastSave: JSON.parse(
          JSON.stringify(gradeSlipEntryItemsObjectByForm)
        ),
      });
    }
  }, [gradeSlipSelectedFormLastSave]);

  useEffect(() => {
    doGradeSlipFetchCategoriesByOrg();
  }, []);

  const onFormSave = () => {
    if (gradeSlipEditedEntries.length > 0) {
      let choice = window.confirm("Unsaved entries... Continue?");
      if (choice) doGradeSlipSave(gradeSlipSelectedForm, onBeforeFormClose);
    } else doGradeSlipSave(gradeSlipSelectedForm, onBeforeFormClose);
  };
  const onBeforeFormClose = (closeNested = false) => {
    if (Object.values(gradeSlipEditedEntries).length > 0) {
      let choice = window.confirm("Unsaved entries... Continue?");
      if (choice) formClose(closeNested);
    } else formClose(closeNested);
  };
  const formClose = (closeNested = false) => {
    if (closeNested) doNestedDialogClose();
    doGradeSlipUpdateSelectedForm(null);
  };

  const onFormSelected = (item) => {
    if (!item) {
      doGradeSlipSave(
        Object.assign(
          {},
          {
            name: profileActiveData.name,
            org_id: orgsByRoute.id,
            profile_id: profileActiveData.id,
            event: gradeSlipEvalType,
            summary: "---",
          }
        ),
        (result) => selectForm({ ...result }),
        true
      );
    } else selectForm(item);
  };
  const selectForm = (item) => {
    doGradeSlipUpdateSelectedForm(item);
  };
  const onDeleteForm = (item) => {
    doGradeSlipRemoveForm(
      Object.assign(
        {},
        {
          id: item.id,
          summary: item.summary,
          profile_id: item.profile_id,
        }
      )
    );
  };

  const renderGradeSlipList = () => {
    if (_isLoading && _isSaving) {
      return (
        <div className="col-sm d-flex align-items-center border-bottom py-2">
          <Loader />
        </div>
      );
    } else if (gradeSlipItemsByEvalType.length === 0) {
      return (
        <div className="container-fluid my-auto h-100 w-100 py-2 d-flex align-items-center justify-content-center">
          <div
            className="d-flex flex-column my-auto "
            style={{ fontSize: "2.5rem" }}
          >
            <div className="mx-auto">
              <i
                className="mdi mdi-alert-circle-outline mr-2"
                style={{
                  color: "#3b5998",
                  textShadow: "1px 1px 2px rgba(0,0,0,.15)",
                }}
              />
            </div>
            <div className="mx-auto">
              <div className="text-value-sm my-auto text-center">
                Nothing found.
              </div>
            </div>
            {!viewedFromProfilePage && (
              <button
                className="btn btn-outline-success mx-auto mt-3"
                onClick={() => onFormSelected()}
              >
                Create New
                <span className="mdi mdi-plus-circle ml-2" />
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="container no-gutters ml-2">
          <div className="col-sm">
            <h6 className="mt-2">
              Select a grade-slip below associated with{" "}
              <strong>{profileActiveData.name}</strong>
            </h6>
            <div className="list-group">
              {gradeSlipItemsByEvalType.map((item, idx) => {
                let { create_date, org_id, mission_id, atpm_sig } = item;

                let atpm_sig_parsed = atpm_sig
                  ? JSON.parse(atob(atpm_sig.split(".")[0]))
                  : "";
                let linkedMission = find(gradeSlipSelectableMissions, [
                  "value",
                  mission_id,
                ]);
                let createDateFormatted = new Date(
                  create_date
                ).toLocaleDateString();
                return (
                  <div
                    key={idx}
                    className="d-flex list-group-item list-group-item-action row"
                    style={{ borderRadius: 0 }}
                  >
                    {!isProfileActiveAtpm && !item.viewed && (
                      <span
                        className="mdi mdi-circle text-warning position-absolute my-auto"
                        style={{ left: 10, top: 25, fontSize: "smaller" }}
                      />
                    )}
                    {viewedFromProfilePage && (
                      <div className="col-sm-1 d-flex flex-lg-column">
                        <small>Org.</small>
                        <strong>{orgsItemsObject[org_id].label}</strong>
                      </div>
                    )}
                    <div className="col-6 col-lg-3 d-flex flex-lg-column pl-0 mr-auto">
                      <small className="d-none d-lg-inline">Summary</small>
                      <strong className="text-truncate">{item.summary}</strong>
                    </div>
                    <div className="col-sm-3 pl-0 d-none d-lg-flex flex-column mr-auto">
                      <small>Mission</small>
                      {linkedMission ? (
                        <button
                          className="btn px-0 btn-sm btn-link d-inline-block text-left text-truncate"
                          onClick={() => {
                            doNestedDialogClose();
                            doDialogClose();
                            doUpdateUrl(
                              `/${orgsByRoute.slug}/mission/${linkedMission.slug}`
                            );
                          }}
                        >
                          {linkedMission.label}
                        </button>
                      ) : (
                        "Not Applicable"
                      )}
                    </div>
                    <div className="col-6 col-lg-1 d-flex flex-lg-column pl-0 mr-auto">
                      <small className="d-none d-lg-inline">Date Created</small>
                      <strong className="ml-auto ml-lg-0">
                        {createDateFormatted}
                      </strong>
                    </div>
                    <div className="col-12 col-lg-2 d-flex justify-content-end justify-content-lg-center">
                      <div className="d-flex flex-column">
                        <small>Status</small>
                        <h6
                          className={`text-${
                            item.atpm_sig ? "success" : "muted"
                          } mr-auto ml-0`}
                        >
                          {item.atpm_sig && (
                            <span className="mdi mdi-check-circle mr-1" />
                          )}
                          {item.atpm_sig ? "Signed" : "Incomplete"}
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 d-flex flex-column flex-xl-row justify-content-end px-0">
                      {!viewedFromProfilePage && (
                        <button
                          className="btn btn-sm btn-danger my-xl-auto mt-1 mx-xl-1 order-3 order-xl-1"
                          name="deleteBtn"
                          onClick={() => onDeleteForm(item)}
                        >
                          Delete
                        </button>
                      )}

                      <PrintButton
                        state={{
                          ...processObjIntoFormValues(item),
                          rows: gradeSlipEntryItemsPrintableAll[item.id],
                        }}
                        buttonRenderFn={(clickFn) => {
                          return (
                            <button
                              className="btn btn-sm btn-secondary my-auto mx-xl-1 order-2"
                              onClick={clickFn}
                              title="Download"
                            >
                              Print
                            </button>
                          );
                        }}
                        options={[
                          {
                            resultFileName: "Grade Slip",
                            file: "grade-slip.template.json",
                          },
                        ]}
                      >
                        <i className="mdi mdi-printer" />
                      </PrintButton>
                      <button
                        className="btn btn-sm my-xl-auto mb-1 btn-primary ml-xl-1 order-1 order-xl-3"
                        onClick={() => onFormSelected(item)}
                      >
                        <span>{!viewedFromProfilePage ? "Edit" : "View"}</span>
                      </button>
                      <PrintButton
                        className=""
                        state={{
                          ...item,
                          event: gradeSlipEvalType,
                          event_str: gradeSlipAllTypes[gradeSlipEvalType]
                            ? gradeSlipAllTypes[gradeSlipEvalType].label
                            : "",
                          profile_id: profileActiveData.id,
                          name: profileActiveData.name,
                          suac_debrief_complete_yes:
                            item.suac_debrief_complete === "yes",
                          suac_debrief_complete_no:
                            item.suac_debrief_complete === "no",
                          additional_training_yes:
                            item.additional_training === "yes",
                          additional_training_no:
                            item.additional_training === "no",
                          overall_grade_sat: item.overall_grade === "sat",
                          overall_grade_unsat: item.overall_grade === "unsat",
                          overall_grade_na: item.overall_grade === "na",
                          org_id: org_id,
                          mission_name: linkedMission ? linkedMission.name : "",
                          atpm_sig: atpm_sig,
                          atpm_sig_parsed: atpm_sig_parsed,
                          rows: gradeSlipEntryItemsPrintableAll[item.id],
                        }}
                        buttonRenderFn={(clickFn) => {
                          return (
                            <button
                              className="btn btn-sm btn-secondary my-xl-auto"
                              onClick={clickFn}
                              title="Download"
                            >
                              <i className="mdi mdi-printer"></i>
                            </button>
                          );
                        }}
                        options={[
                          {
                            resultFileName: "Grade Slip",
                            file: "grade-slip.template.json",
                          },
                        ]}
                      >
                        <i className="mdi mdi-printer" />
                      </PrintButton>
                    </div>
                  </div>
                );
              })}
              {!viewedFromProfilePage && (
                <div
                  className="d-flex list-group-item row w-100"
                  style={{ borderRadius: 0 }}
                >
                  <button
                    className="btn btn-outline-success mx-auto"
                    onClick={() => onFormSelected()}
                  >
                    Create New
                    <span className="mdi mdi-plus-circle ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  const renderBody = () => {
    if (_isLoading || _isSaving || _isSigning)
      return (
        <div className="my-auto">
          <Loader />
        </div>
      );
    else if (gradeSlipSelectedForm && gradeSlipSelectedForm.id) {
      return (
        <GradeSlipForm
          onFormSave={onFormSave}
          onBeforeFormClose={onBeforeFormClose}
        />
      );
    } else return renderGradeSlipList();
  };
  return (
    <div className="modal-content" style={{ overflowY: "visible" }}>
      <div className="modal-header">
        <div className="d-flex flex-column">
          <h5 className="modal-title">SUAC Grade Slip</h5>
          <h6 className="text-muted mt-2">
            The proponent agency is HQ Aviation.
          </h6>
        </div>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={() => onBeforeFormClose(true)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflowY: "visible" }}>
        <div className="container-fluid">
          <div className="row">
            {!(gradeSlipSelectedForm && gradeSlipSelectedForm.id) && (
              <div className="col-12 container-fluid h-100 d-flex justify-content-between mb-2 d-lg-block">
                <GradeSlipTabs defaultGradeSlip={defaultGradeSlip} />
              </div>
            )}
            <div className="col-12 d-flex flex-column">{renderBody()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  "selectGradeSlipLinkedMission",
  "selectRouteInfo",
  "selectProfileActiveData",
  "selectIsProfileActiveAtpm",
  "selectGradeSlipItemsByEvalType",
  "selectGradeSlipIsLoading",
  "selectGradeSlipIsSaving",
  "selectGradeSlipIsSigning",
  "doGradeSlipSave",
  "doGradeSlipRemoveForm",
  "selectGradeSlipEditedEntries",
  "doGradeSlipUpdateSelectedForm",
  "doNestedDialogClose",
  "doDialogClose",
  "doGradeSlipFetchCategoriesByOrg",
  "selectOrgsByRoute",
  "selectGradeSlipAllTypes",
  "selectGradeSlipEvalType",
  "selectGradeSlipSelectedForm",
  "doGradeSlipUpdateState",
  "doUpdateUrl",
  "selectGradeSlipSelectedFormLastSave",
  "selectGradeSlipEntryItemsObjectByForm",
  "selectOrgsItemsAsOptionsWithId",
  "selectGradeSlipEntryItemsPrintableAll",
  "selectGradeSlipSelectableMissions",
  GradeSlip
);
