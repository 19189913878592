import React from "react";
import { connect } from "redux-bundler-react";
import ApprovalNotesForm from "./approval-notes-form";

class ApprovalItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNoteForm: false,
      rejected: false,
      acknowledge: false,
    };
    this.handleSign = this.handleSign.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.showNoteFormApprove = this.showNoteFormApprove.bind(this);
    this.showNoteFormReject = this.showNoteFormReject.bind(this);
    this.renderRejectNotes = this.renderRejectNotes.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderStatement = this.renderStatement.bind(this);
    this.renderNotes = this.renderNotes.bind(this);
  }

  handleSign() {
    const { onSign, item } = this.props;
    this.setState(
      {
        showNoteForm: false,
      },
      () => {
        onSign(item);
      }
    );
  }

  showNoteFormApprove() {
    this.setState({
      showNoteForm: true,
      rejected: false,
    });
  }

  showNoteFormReject() {
    this.setState({
      showNoteForm: true,
      rejected: true,
    });
  }

  handleCancel() {
    this.setState({
      showNoteForm: false,
      rejected: false,
    });
  }

  handleReject() {
    const { doDelete, doFetch } = this.props;
    this.setState(
      {
        showNoteForm: false,
      },
      () => {
        doDelete({}, doFetch, true);
      }
    );
  }

  renderRejectNotes() {
    const { item } = this.props;
    const { showNoteForm, rejected } = this.state;
    if (!showNoteForm) return null;
    return (
      <ApprovalNotesForm
        isRejecting={rejected}
        onSave={rejected ? this.handleReject : this.handleSign}
        onCancel={this.handleCancel}
        item={item}
      />
    );
  }

  renderButton() {
    const { showNoteForm, acknowledge } = this.state;
    let {
      iconsOnly,
      atpmTransferCurrentUserBackup,
      sig,
      active,
      item,
      approvalRolesByLoggedIn,
      showCheckbox,
      domainsItemsByGroup,
    } = this.props;
    if (showNoteForm) return null;

    let hasRights = item.approval_role_id
      ? approvalRolesByLoggedIn.indexOf(item.approval_role_id) !== -1
      : true;
    // if director or commander, able to sign for atpm
    let approvalRoles = domainsItemsByGroup.approval_role;
    let backupAtpmId = approvalRoles.filter(
      (obj) => obj.val === "Backup ATPM"
    )[0].id;
    let userIsBackupAtpm = approvalRolesByLoggedIn.includes(backupAtpmId);
    let dirCmd = approvalRoles
      .filter((item) => item.val === "Commander" || item.val === "Director")
      .map((x) => x.id);

    //need to add in here -> if user has backup atpm role and they currently have atpm authority
    // append approval_role_id for atpm if this is true ^
    /** old way was to check if backup atpm had privileges transferred, now all backups can sign here */
    // hasRights =
    //   hasRights ||
    //   (item.approval_role === "Aircrew Training Program Manager" &&
    //     atpmTransferCurrentUserBackup &&
    //     atpmTransferCurrentUserBackup.length > 0) ||
    //   (item.approval_role === "Aircrew Training Program Manager" &&
    //     approvalRolesByLoggedIn.some((val) => dirCmd.includes(val)));

    hasRights =
      hasRights ||
      (item.approval_role === "Aircrew Training Program Manager" &&
        userIsBackupAtpm) ||
      (item.approval_role === "Aircrew Training Program Manager" &&
        approvalRolesByLoggedIn.some((val) => dirCmd.includes(val)));

    if (!hasRights) active = false;
    if (!acknowledge && showCheckbox) active = false;
    if (!sig) {
      return (
        <div>
          <button
            onClick={this.showNoteFormApprove}
            disabled={!active}
            className={`btn btn-lg btn-outline-${
              active ? "success" : "secondary"
            } mr-3`}
          >
            <i className="mdi mdi-certificate mr-1"></i>
            {iconsOnly ? "" : "Approve"}
          </button>
          <button
            onClick={this.showNoteFormReject}
            disabled={!active}
            className={`btn btn-lg btn-outline-${
              active ? "danger" : "secondary"
            }`}
          >
            <i className="mdi mdi-cancel mr-1"></i>
            {iconsOnly ? "" : "Reject"}
          </button>
        </div>
      );
    } else {
      const sigInfo = JSON.parse(atob(sig.split(".")[0]));
      return (
        <button className="btn btn-lg btn-success" active="true">
          <i className="mdi mdi-certificate float-left mr-1"></i>
          <div className="text-left ml-4">
            <small>
              <div>{sigInfo.username}</div>
              <div>
                <em>{sigInfo.date}</em>
              </div>
            </small>
          </div>
        </button>
      );
    }
  }

  renderCheckbox() {
    const { sig } = this.props;
    return (
      <div className="d-flex justify-content-start">
        <label className={`form-label ${!!sig ? "text-muted" : ""}`}>
          <input
            className="form-input mr-2"
            type="checkbox"
            onChange={(e) =>
              this.setState({ acknowledge: !this.state.acknowledge })
            }
            disabled={!!sig}
            checked={!!sig || this.state.acknowledge}
          />
          I have read the adjudications above.
        </label>
      </div>
    );
  }

  renderStatement() {
    const { showNoteForm } = this.state;
    const { statement, showCheckbox } = this.props;
    if (showNoteForm) return null;
    return (
      <div>
        {showCheckbox && this.renderCheckbox()}
        <p>{statement}</p>
      </div>
    );
  }

  renderNotes() {
    const { showNoteForm } = this.state;
    if (showNoteForm) return null;
    const { notes } = this.props;
    if (!notes) return null;
    return (
      <div className="mt-3">
        <strong>Notes:</strong>
        {notes.map((note, i) => {
          return (
            <div key={i}>
              <div>
                <small>
                  <strong>
                    <i
                      className={`mdi mdi-${
                        note.is_rejected ? "cancel" : "certificate"
                      } text-${note.is_rejected ? "danger" : "success"} mr-1`}
                    ></i>
                    {`${new Date(note.last_updated_date).toLocaleString()} - ${
                      note.last_updated_by_name
                    }`}
                  </strong>
                </small>
              </div>
              <p
                className="pl-3"
                dangerouslySetInnerHTML={{ __html: note.note }}
                style={{ whiteSpace: "pre-wrap" }}
              ></p>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { title } = this.props;
    return (
      <div>
        <h5>{title}</h5>
        <div className="clearfix">
          <div className="float-left mr-3">{this.renderButton()}</div>
          {this.renderRejectNotes()}
          {this.renderStatement()}
        </div>
        {this.renderNotes()}
        <hr />
      </div>
    );
  }
}

export default connect(
  "selectApprovalRolesItems",
  "selectApprovalRolesByLoggedIn",
  "selectAtpmTransferCurrentUserBackup",
  "selectDomainsItemsByGroup",
  ApprovalItem
);
